
.App {
  text-align: center;
  width: 100%;
  height: 100%;

  border-radius: 16px;
}
.container{
  align-content: center;
  display: flex;
  flex-direction: column;
  background: rgba(255,255,255,0.2);
  padding: 2%;
  border-radius: 16px;
  color: #fff;
  border: solid 1px rgba(255,255,255,0.3);
  box-shadow: 0 4px 30px rgba(0,0,0,0.1);
  backdrop-filter: blur(5px);
}
.list{
  color: transparent;
  font-family: studiofeixen,Arial,Helvetica,sans-serif;
  letter-spacing: .05rem;
  font-weight: 800;
  -webkit-text-stroke: 2px #343333;
  font-variation-settings: "wght" 800,"wdth" 100;
  font-size: xx-large;
  display:inline;
  padding: 5%;
  height: 20%;
  
}
.scroll {
  width: 100%;
  height: 500px;
   overflow: scroll;
  text-align:justify;
  
}
.btn{
  cursor: pointer;
}
.picture{
  width: 20%;
  border-radius: 5%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.intro{
  margin-top: 2%;
  color: transparent;
  font-family: studiofeixen,Arial,Helvetica,sans-serif;
  letter-spacing: .05rem;
  font-weight: 800;
  -webkit-text-stroke: 2px #343333;
  font-variation-settings: "wght" 800,"wdth" 100;
  font-size: xx-large;
  display:inline;

}
.intro2{
  position: relative;
  color: transparent;
  font-family: Alkatra,Arial,Helvetica,sans-serif;
  letter-spacing: .25rem;
  color: #3e3c3c;
  font-size: large;
}
.rotate{
  animation: App-logo-spin  5s linear;
}
.pages{
 display: flex;
 flex-wrap: wrap;
 justify-content: center;
}

.fade-in-image { animation: fadeIn 2s linear; width: 100%;}
.fade-in-image2 { animation: fadeIn 0.5s linear; width: 100%;}
@keyframes fadeIn {
  0% { opacity: 0; width: 0%;}
  100% { opacity: 1;}
}

@keyframes fadeIn2 {
  0% { opacity: 0; width: 0%;}
  100% { opacity: 1;}
}

.a {
  font-size: 1.25rem;
  color: #343333;
  font-family: Pacifico,Arial,Helvetica,sans-serif;
  text-transform: lowercase;
  text-decoration: none;
}
